import React from "react";

export default function MatomoTracking() {
  React.useEffect(() => {
    var _mtm = (window._mtm = window._mtm || []);
    _mtm.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start" });
    var d = document,
      g = d.createElement("script"),
      s = d.getElementsByTagName("script")[0];
    g.async = true;
    g.src = "https://cdn.matomo.cloud/hoox.matomo.cloud/container_JC7Ghd5k.js";
    s.parentNode.insertBefore(g, s);

    /*
    var currentUrl = location.href;
    window.addEventListener("hashchange", function () {
      _paq.push(["setReferrerUrl", currentUrl]);
      currentUrl = "/" + window.location.hash.substr(1);
      _paq.push(["setCustomUrl", currentUrl]);
      _paq.push(["setDocumentTitle", "My New Title"]);

      // remove all previously assigned custom variables, requires Matomo (formerly Piwik) 3.0.2
      _paq.push(["deleteCustomVariables", "page"]);
      _paq.push([
        "AbTesting::create",
        {
          name: "theExperimentName",
          includedTargets: [
            {
              attribute: "url",
              type: "starts_with",
              value: "http://www.example.org",
              inverted: "0",
            },
          ],
          excludedTargets: [],
          variations: [
            {
              name: "original",
              activate: function (event) {
                // usually nothing needs to be done here
              },
            },
            {
              name: "blue",
              activate: function (event) {
                // eg $('#btn').attr('style', 'color: ' + this.name + ';');
              },
            },
          ],
        },
      ]);
      _paq.push(["trackPageView"]);

      // make Matomo aware of newly added content
      var content = document.getElementById("content");
      _paq.push(["MediaAnalytics::scanForMedia", content]);
      _paq.push(["FormAnalytics::scanForForms", content]);
      _paq.push(["trackContentImpressionsWithinNode", content]);
      _paq.push(["enableLinkTracking"]);
    });
    */
  }, []);

  return null;
}

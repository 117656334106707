import Axios from "../util/apiConfig";
import { POSTER_WEB_BANNER, POSTER_WEB_LIST } from "../util/constants";

// Add a request interceptor
Axios.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("authToken");
  // remove GetNewToken flows and grab a authentication token
  // if(!token){
  //  await GetNewToken();
  // }
  config.headers.Authorization = token;

  return config;
});

Axios.interceptors.response.use(
  (response) => {
    // Yanıt başarılı oldu
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.clear();
      //window.location.href = "/";   sonsuz donguyu engellemek ıcın eklendi.
    }
  }
);

export async function getCategories() {
  return Axios.get("/con/categories")
    .then((result) => result.data)
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        GetNewToken();
      }
      return error;
    });
}
export async function getProducts() {
  return Axios.get("/cus/product")
    .then((result) => result.data)
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        //GetNewToken();
      }
      return error;
    });
}

/*
export async function getContentDetail(contentId) {
  return Axios.get(`cs/video/${contentId}`)
    .then((result) => result.data)
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        //GetNewToken();
      }
      return error;
    });
}
*/

export async function getContentDetailByGuid(guid) {
  return Axios.get(`cs/v4/video/${guid}`)
    .then((result) => result.data)
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        GetNewToken();
      }
      return error;
    });
}

export async function getPaymentMeta(customerGuid, packageId, duration) {
  return Axios.get(
    `cus/payments/prturls/${customerGuid}/${packageId}/${duration}`
  )
    .then((response) => {
      if (response.status === 200) {
        return response;
      }
    })
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        //GetNewToken();
      }
      console.error(error);
    });
}

export async function getIframeUrl(responseURL, productId) {
  /* Axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  return Axios.post(
    "https://api.test.paratim.com.tr/integration/post/getIframeUrl",
    {
      merchantId: 592,
      subMerchantId: 2044,
      payment_type_id: 6,
      session_token:
        "c7dcf99078fc4b02b5031f4504341b7eb57fe32be4e0b8e527e2ec663a224a66",
      products: [{ id: productId }],
      responseURL,
    }
  ).then((response) => {
    if (response.status === 200) {
      return response;
    }
  }); */
}

export async function addBookmark(contentId, time, duration) {
  return Axios.post("con/bookmarks", {
    contentId,
    time,
    duration,
  })
    .then((response) => {
      if (response.status === 200) {
        return response;
      }
    })
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        GetNewToken();
      }
      console.error(error);
    });
}

export async function getBookmarked(contentId) {
  return Axios.get(`con/bookmarks?contentId=${contentId}`)
    .then((response) => {
      if (response.status === 200) {
        return response;
      }
    })
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        //GetNewToken();
      }
      console.error(error);
    });
}

export async function getFavorites() {
  return Axios.get("/con/videos/myPage?codes=8")
    .then((result) => result.data)
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        //GetNewToken();
      }
      return error;
    });
}

export async function addFavorite(contentId) {
  return Axios.post(`con/videos/${contentId}/favorite`, {})
    .then((response) => {
      if (response.status === 200) {
        return response;
      }
    })
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        GetNewToken();
      }
      console.error(error);
    });
}

export async function removeFavorite(contentId) {
  return Axios.delete(`con/videos/${contentId}/favorite`, {})
    .then((response) => {
      if (response.status === 200) {
        return response;
      }
    })
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        GetNewToken();
      }
      console.error(error);
    });
}

/*
export async function logout() {
  return Axios.post("aut/auth/logout", {})
    .then((response) => {
      //console.log("response:", response);
      if (response?.status === 200) {
      }
      return response;
    })
    .catch((error) => {
      if (
        (error.response && error.response.status) === 401 ||
        ((error.response && error.response.status) === 500 &&
          (error.response && error.response.data.status) === 440)
      ) {
      }
      console.error(error);
    });
}

export async function login(username, password) {
  return Axios.post("aut/auth/v2/fkgt", {
    //post("aut/auth/login", {
    deviceMacId: "XC-12",
    deviceName: "Web",
    password,
    sessionID: "12345678945",
    tenantID: "1",
    userID: 0,
    username,
  })
    .then((response) => {
      if (response.data.code === 200) {
        localStorage.setItem("authToken", response.data.data.token);
        localStorage.setItem("tokenDate", new Date().getTime() + 5);
        localStorage.setItem("isUser", "true");
        localStorage.setItem("userName", response.data.data.username);
        //localStorage.setItem("userId", response.data.data.id);
        //localStorage.setItem("customerId", response.data.data.customerId);
        localStorage.setItem("guid", response.data.data.guid);
      }
      return response;
    })
    .catch((error) => {
      if (
        error.response !== undefined &&
        (error.response.status === 401 ||
          (error.response.status === 500 && error.response.data.status === 440))
      ) {
        GetNewToken();
      }
      return error;
    });
}

export async function forceLogin(username, password) {
  return Axios.post("aut/auth/v2/fkgt?accept=true", { // aut/auth/login?accept=true
    deviceMacId: "XC-12",
    deviceName: "Web",
    password,
    sessionID: "12345678945",
    tenantID: "1",
    userID: 0,
    username,
  })
    .then((response) => {
      if (response.data.code === 200) {
        localStorage.setItem("authToken", response.data.data.token);
        localStorage.setItem("tokenDate", new Date().getTime() + 5);
        localStorage.setItem("isUser", "true");
        localStorage.setItem("userName", response.data.data.username);
        //localStorage.setItem("userId", response.data.data.id);
        //localStorage.setItem("customerId", response.data.data.customerId);
        localStorage.setItem("guid", response.data.data.guid);
      }
      return response;
    })
    .catch((error) => {
      if (
        error.response !== undefined &&
        (error.response.status === 401 ||
          (error.response.status === 500 && error.response.data.status === 440))
      ) {
        GetNewToken();
      }
      return error;
    });
}
*/

/*
export async function resetPassword(email) {
  return Axios.post("aut/cst/resetpassword", {
    email,
  })
    .then((response) => {
      if (response.data.code === 200) {
      }
      return response;
    })
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        GetNewToken();
      }
      return error;
    });
}

export async function resetPasswordCode(email) {
  return Axios.post("aut/cst/rstpswcode", {
    email,
  })
    .then((response) => {
      if (response.data.code === 200) {
      }
      return response;
    })
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        GetNewToken();
      }
      return error;
    });
}

export async function resetPasswordV2(code, newPassword, newPasswordAgain) {
  return Axios.post("aut/cst/v2/resetpassword", {
    code,
    newPassword,
    newPasswordAgain,
  })
    .then((response) => {
      if (response.data.code === 200) {
      }
      return response;
    })
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        GetNewToken();
      }
      return error;
    });
}
*/

export async function changeUser(
  name,
  surname,
  city,
  email,
  gsmno,
  address,
  indetityNo,
  iytPermission
) {
  return Axios.put("cus/customer/info", {
    customerInfo: {
      city,
      name,
      gender: "Male",
      gsmno,
      surname,
      email,
      address,
      indetityNo,
      iytPermission,
    },
  })
    .then((response) => response)
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        GetNewToken();
      }
      return error;
    });
}

export async function contactUs(message, subject) {
  return Axios.post("cus/messages", {
    message,
    subject,
  })
    .then((response) => response)
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        GetNewToken();
      }
      return error;
    });
}

export async function changePassword(oldpassword, newpassword, renewpassword) {
  return Axios.put("aut/auth/chnpas", {
    oldPassword: oldpassword,
    newPassword: newpassword,
    reNewPassword: renewpassword,
  })
    .then((response) => response)
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        GetNewToken();
      }
      return error;
    });
}

/*
export async function getEpisodesOLD(seasonID) {
  return Axios.get(`con/videos/${seasonID}/v2/childs?codes=` + POSTER_WEB_LIST)
    .then((result) => result.data)
    .catch((error) => {
      if (error.response.status === 401) {
        GetNewToken();
      }
      return error;
    });
}

*/
export async function getEpisodesByGuid(seasonID) {
  return Axios.get(`con/videos/v3/${seasonID}/childs`)
    .then((result) => result.data)
    .catch((error) => {
      if (error.response.status === 401) {
        GetNewToken();
      }
      return error;
    });
}

/*
export async function customerCreate(
  fullName,
  email,
  password,
  gsmNo,
  iytPermission
) {
  return Axios.post("/cs/customer/create", {
    email,
    password,
    name: fullName,
    gsmNo,
    iytPermission,
  })

    .then((response) => {
      if (response.data.code === 200) {
      }
      return response;
    })
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        //GetNewToken();
      }

      return error;
    });
}


export async function customerCreateV2(
  fullName,
  email,
  password,
  gsmNo,
  iytPermission
) {
  return Axios.post("/cs/customer/v2/create", {
    email,
    password,
    name: fullName,
    gsmNo,
    iytPermission,
  })

    .then((response) => {

      return response;
    })
    .catch((error) => {

      return error;
    });
}
*/

export async function customerCreateV3(
  fullName,
  email,
  password,
  gsmNo,
  iytPermission
) {
  return Axios.post("/cs/customer/v3/create", {
    email,
    password,
    name: fullName,
    gsmNo,
    iytPermission,
  })

    .then((response) => {
      /* if (response.data.code === 200) {
      } */
      return response;
    })
    .catch((error) => {
      /* if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        //GetNewToken();
      } */

      return error;
    });
}

export async function getRelatedContent(contentId) {
  return Axios.get(`/con/videos/${contentId}/related?codes=8`)
    .then((result) => result.data)
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        GetNewToken();
      }
      return error;
    });
}

export async function getRelatedContentByGuid(guid) {
  return Axios.get(`/con/videos/v2/${guid}/related`)
    .then((result) => result.data)
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        GetNewToken();
      }
      return error;
    });
}

export async function getRecommended() {
  return Axios.get(`/con/videos/recommended?codes=8`)
    .then((result) => result.data)
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
      }
      return error;
    });
}

export async function getUserDetails() {
  return Axios.get("/cus/customer/info")
    .then((result) => result.data)
    .catch((error) => {
      if (
        error?.response?.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        GetNewToken();
      }
      return error;
    });
}

export async function GetNewToken() {
  /*
  fetch(`${apiUrl}aut/auth/guest`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      device: 'MobileIos',
    },
  })
    .then((res) => res.json())
    .then((response) => {
      localStorage.setItem('authToken', response.data);
      localStorage.setItem('tokenDate', new Date().getTime() + 5);
      localStorage.setItem('isUser', 'false');
      localStorage.removeItem('userName');
    })
    .catch((error) => {
      console.error('GetNewToken error', error);
    });
    */
}

// `/con/bannercontents?codes=3&languageCode=1&categoryId=${categoryId}`
export async function getBanners(categoryId) {
  const params = { categoryId };

  return Axios.get("/con/banners/v2/" + POSTER_WEB_BANNER, { params }) // return Axios.get(`/con/banners/${categoryId}`)
    .then((result) => result.data)
    .catch((error) => {
      if (error.response.status === 401) {
        GetNewToken();
        //return getBanners();
      }
      return error;
    });
}

export async function Search(searchKey) {
  return Axios.get(`/con/videos/search?codes=8&text=${searchKey}`)
    .then((result) => result.data)
    .catch((error) => {
      if (error.response.status === 401) {
        GetNewToken();
        //return getBanners();
      }
      return error;
    });
}
export async function getSearchSuggest() {
  return Axios.get("con/videos/suggested?codes=8")
    .then((result) => result.data)
    .catch((error) => {
      if (error.response.status === 401) {
        GetNewToken();
      }
      return error;
    });
}

export async function purchaseContent(contentId, price) {
  return Axios.get(
    `/cs/vods/${contentId}/purchase?price=${price}&type=RENT&specialPrice=${price}`
  )
    .then((result) => result.data)
    .catch((error) => {
      if (error.response.status === 401) {
        GetNewToken();
        //return purchaseContent();
      }
      return error;
    });
}

export async function getLabelsByCategory(categoryId) {
  return Axios.get(`/con/categories/${categoryId}/subcategory`)
    .then((result) => result.data)
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        GetNewToken();
      }
      return error;
    });
}

export async function getContentByLabel(labelId) {
  return Axios.get(`/con/subcategory/${labelId}/videos?codes=8`)
    .then((result) => result.data)
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        GetNewToken();
      }
      return error;
    });
}

export async function getContentsByCategory(categoryId) {
  return Axios.get(
    categoryId
      ? `/con/categories/${categoryId}/videos/detail?codes=8`
      : "con/videos/v2/main"
  )
    .then((result) => result.data)
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        GetNewToken();
      }
      return error;
    });
}

export async function getWatched() {
  return Axios.get("/con/videos/watched?codes=8")
    .then((result) => result.data)
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        GetNewToken();
      }
      return error;
    });
}

/*
export async function directSubscribeWithID(id, productId) {
  // Just For testing. will be REMOVED after payment integration.
  return Axios.post(
    "/cus/subscriber/start/v2/?customerId=" +
      id +
      "&packageId=" +
      productId +
      "&duration=12"
  )
    .then((result) => {
      return result;
    })
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
      }
      return error;
    });
}


export async function directSubscribeWithGuid(guid) {
  // Just For testing. will be REMOVED after payment integration.
  return Axios.post(
    "/cus/subscriber/start/?customerGuid=" + guid + "&packageId=100&duration=12"
  )
    .then((result) => {
      return result;
    })
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
      }
      return error;
    });
}

export async function getPayment(customerGuid, packageId) {
  return Axios.post(
    `/cus/subscriber/start/payment/${customerGuid}/${packageId}`
  )
    .then((result) => {
      return result;
    })
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
      }
      return error;
    });
}
*/

export async function getLandingPageBAnners() {
  return Axios.get(`/con/videos/landing/banners`)
    .then((result) => result.data)
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        //GetNewToken();
      }
      return error;
    });
}

export async function getLandingPageContents() {
  return Axios.get(`/con/videos/landing/contents`)
    .then((result) => result.data)
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        //GetNewToken();
      }
      return error;
    });
}

export default getLabelsByCategory;

import { useEffect, useState } from "react";
import { useAuth } from "../components/AuthContext";
import { getProducts, getUserDetails } from "../service/contentService";
import {
  Form,
  Button,
  FormSelect,
  Col,
  Row,
  FormControl,
} from "react-bootstrap";
import styled from "styled-components";
import HooxModal, { HOOX_MODAL } from "../components/HooxModal";
import { checkCampaign, verifySubscription } from "../service/customerService";
import { useNavigate } from "react-router";
import useModal from "../components/hooks/useModal";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import CCForm from "../components/ccForm/CCForm";

export const InfoField = styled.p`
  display: block;
  background-color: rgb(0 7 58);
  border-radius: 7px;
  border: 1px solid rgba(18 214 223 / 30%);
  width: 100%;
  padding: 10px;
`;

export const SubsFormButtons = styled(`Button`)`
  background-color: transparent;
  border-radius: 7px;
  border: 1px solid rgba(18 214 223 / 30%);
  &:hover {
    background-color: #bface282;
  }

  &:disabled {
    background-color: #40456b;
    border: 1px solid rgb(216 219 223 / 35%);
    color: rgb(216 219 223 / 35%);
  }
`;

export const ProductAreaTitle = styled.div`
  display: block;
  background-color: transparent;
  width: 100%;
  font-size: 18px;
  font-family: GilroyBold;
  margin-bottom: 20px;
`;

export const ProductAreaItem = styled.div`
  display: block;
  background-color: transparent;
  width: 100%;
  font-size: 13px;
  font-family: GilroyLight;
  margin-bottom: 10px;
`;

export const ProductAreaDesc = styled.ul`
  font-family: GilroyLight;
  list-style-type: disc;
  padding-left: ${(props) => (props.isMobile ? "20" : "20")}px;
  li {
    color: white; /* Metin rengi beyaz yap */
    line-height: 25px;
    font-size: ${(props) => (props.isMobile ? "12" : "16")}px;
  }

  /* Yuvarlak noktaların (bullet point'lerin) rengini ayarla */
  li::marker {
    color: #bc00bc;
  }
  margin-bottom: 3px;
`;

export const ProductPrice = styled.div`
  color: #fff;
  font-size: ${(props) => (props.isMobile ? "18" : "28")}px;
  small {
    font-size: 70%; /* Küçük font boyutu */
  }
`;

const TabTitle = styled("div")`
  font-family: GilroyLight;
  font-size: ${(props) => (props.isMobile ? "18" : "32")}px;
  height: 50px;
`;

const PanelContainer = styled("div")`
  display: flex;
  flex-direction: column; /* Paneller alt alta sıralansın */
  align-items: center; /* Yatayda ortala */
  justify-content: center; /* Dikeyde ortala */
  min-height: 100vh; /* Konteynerin yüksekliği viewport yüksekliğine eşit olsun */
  width: 100%;
`;

const PanelArea = styled("div")`
  color: #fff;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 20px;
  padding-top: ${(props) => (props.isMobile ? "10" : "30")}px;
  padding-bottom: ${(props) => (props.isMobile ? "10" : "30")}px;
  padding-left: ${(props) => (props.isMobile ? "5" : "90")}px;
  padding-right: ${(props) => (props.isMobile ? "5" : "90")}px;
  font-size: 13px;
  min-height: 405px;
  margin: 20px;
  width: ${(props) => (props.isMobile ? "95%" : "70%")};
  display: flex;
  justify-content: center;
`;

const Line = styled("hr")`
  color: #12d6df;
  opacity: 1;
`;

const SubInfoText = styled("div")`
  font-size: "12px";
  margin: "1px 5px 1px 3px";
  color: #cccacad1;
  font-family: GilroyLight;
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 30px;
  &:focus {
    border: none;
  }
`;

const StyledFormControl = styled(FormControl)`
  border: 2px solid #a98eff !important;
  border-radius: 10px !important;
  background-color: rgba(255, 255, 255, 0.25) !important;
  font-size: 14px;
  font-family: GilroyLight;
  height: 50px;
  &::placeholder {
    color: rgba(
      253,
      253,
      253,
      0.72
    ); /* Set your desired placeholder color here */
    opacity: 1; /* Ensure the placeholder color is fully opaque */
  }
`;

const InputButton = styled(Button)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ffffff;
  border: none;
  padding: 5px 25px;
  border-radius: 5px;
  color: rgb(149, 149, 149);
  font-size: 16px;
  &:hover {
    background-color: #bc00bc;
    border: none;
  }
  &:focus {
    background-color: #bc00bc;
    border: none;
  }
  &:disabled {
    background-color: rgb(191, 149, 191);
    border: none;
  }
  &:active {
    background-color: #bc00bc;
    border: none;
  }
`;

//const PackageOption = styled(`Row`)``;

function Subscription() {
  const { user } = useAuth();
  const [product, setProduct] = useState({});
  const [products, setProducts] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [isEdu, setIsEdu] = useState(false);
  const navigate = useNavigate();
  const [productDescItems, setProductDescItems] = useState([]);
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });

  const { isShowing: isShowingFail, toggle: toggleFailModal } = useModal();

  const {
    isShowing: isShowingPackageSelection,
    toggle: togglePackageSelectionModal,
  } = useModal();

  const [failMessage, setFailMessage] = useState(null);
  const [cammpaignCode, setCammpaignCode] = useState(null);
  const [cammpaignPrice, setCammpaignPrice] = useState(null);

  useEffect(() => {
    getProducts().then((response) => {
      // TODO: select activeIndex logic
      if (response.data && response.data.length > 0) {
        setProducts(response.data);
        setProduct(response.data[0]);
        setSelectedProduct(response.data[0]);
      } else {
        console.log("Paket alınamadı:", response);
        setFailMessage(response?.message);
        toggleFailModal();
      }
    });
  }, []);

  useEffect(() => {
    getUserDetails()
      .then((response) => {
        //console.log("getUserDetails.response:", response);
        if (response.code === 200) {
          setUserDetails(response.data);
          //console.log("getUserDetails.response.data:", response.data);
          const domain = response?.data?.email?.split("@")[1];
          setIsEdu(domain.toLowerCase().endsWith("edu.tr"));
        } else {
          navigate("/user/register");
        }
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    var externalId = "150";

    if (isEdu) {
      externalId = "edu";
    }

    console.log("externalId:", externalId);
    const foundProduct = products.find(
      (product1) => product1.externalId === externalId
    );

    console.log("foundProduct:", foundProduct);
    if (foundProduct) {
      setProduct(foundProduct);
    } else {
      console.warn(`Product with externalId not found`);
    }
  }, [isEdu, products]);

  useEffect(() => {
    //console.log("Product:", product);
    setDescription();
  }, [product]);

  function setSelectedProduct(spId) {
    //console.log("e : ", e.target.value);
    const selectedProduct = products.find(
      (product) => product.id === parseInt(spId)
    );

    if (selectedProduct) {
      setProduct(selectedProduct);
    } else {
      console.warn(`Product with id ${spId} not found`);
    }
  }

  function setDescription() {
    if (product && product.description) {
      const stringParts = product?.description
        .split("|")
        .map((part) => part.trim());
      setProductDescItems(stringParts);
    } else {
      setProductDescItems([]);
    }
  }

  const handleCampaignCodeChange = (e) => {
    setCammpaignCode(e.target.value);
  };

  const handleCampaignCodeCheck = (e) => {
    console.log("apply: cammpaignCode:", cammpaignCode);

    if (product && product?.externalId && cammpaignCode) {
      checkCampaign(product?.externalId, cammpaignCode).then((response) => {
        console.log("checkCampaign:", response);
        if (response?.code === 200) {
          setCammpaignPrice(response.data.directPriceWithTax);
        } else {
          setFailMessage(response?.message);
          toggleFailModal();
        }
      });
    }
  };

  const handleCancelCampaignCode = (e) => {
    console.log("cancel: cammpaignCode:", cammpaignCode);
    setCammpaignCode(null);
    setCammpaignPrice(null);
  };

  return (
    <>
      <div
        className="container"
        style={{ marginTop: isMobile ? "" : "5vh", marginBottom: "10vh" }}
      >
        <Row
          style={{
            fontFamily: "GilroyRegular",
            letterSpacing: "1px",
            display: "flex",
            justifyContent: "center", // Yatay hizalama
            alignItems: "center", // Dikey hizalama
          }}
        >
          <Col md={12} style={{ display: "flex", justifyContent: "center" }}>
            <PanelContainer>
              <PanelArea isMobile={isMobile}>
                <Form
                  style={{ marginTop: "2px", width: isMobile ? "90%" : "65%" }}
                >
                  {
                    //userDetails.status === "INACTIVE" ? (
                    <>
                      <Form.Group className="mb-3">
                        <TabTitle>
                          {cammpaignPrice ? (
                            <img
                              src={require("../assets/img/7gun7tl.png")}
                              alt="Campaign Image"
                            />
                          ) : (
                            <span style={{ marginLeft: "15px" }}>
                              {product.priceWithTax?.toFixed(2) + " TL/AY"}
                            </span>
                          )}
                        </TabTitle>
                        <img src={require("../assets/img/line.png")} />
                      </Form.Group>

                      <ProductAreaDesc isMobile={isMobile}>
                        {productDescItems.map((part, index) => (
                          <li key={index}>{part}</li>
                        ))}
                      </ProductAreaDesc>

                      {cammpaignPrice ? (
                        <SubInfoText>
                          * 7gün 7 TL kampanyası, 7 gün sonunda iptal edilmemesi
                          durumunda aboneliğiniz otomatik olarak aylık ücret
                          üzerinden devam edecektir.
                        </SubInfoText>
                      ) : (
                        <SubInfoText>
                          * Abonelikler iptal edilmediğinde otomatik yenilenir.
                        </SubInfoText>
                      )}
                      {isEdu ? (
                        ""
                      ) : (
                        <Form.Group className="mb-1">
                          <InputWrapper>
                            <StyledFormControl
                              id="cmpCode"
                              name="cmpCode"
                              placeholder="Kampanya Kodu"
                              onChange={(event) =>
                                handleCampaignCodeChange(event)
                              }
                              value={cammpaignCode || ""}
                            />
                            {cammpaignPrice ? (
                              <InputButton
                                onClick={(event) =>
                                  handleCancelCampaignCode(event)
                                }
                                //disabled={isEdu}
                              >
                                İptal
                              </InputButton>
                            ) : (
                              <InputButton
                                onClick={(event) =>
                                  handleCampaignCodeCheck(event)
                                }
                                //disabled={isEdu}
                              >
                                Uygula
                              </InputButton>
                            )}
                          </InputWrapper>
                        </Form.Group>
                      )}

                      {isEdu ? (
                        <SubInfoText>
                          * Sadece web sitesi üzerinde edu.tr eposta adresiyle
                          kayıt olan kullanıcılar için geçerlidir.
                        </SubInfoText>
                      ) : (
                        <SubInfoText>
                          * Kampanya kodu sadece web üzerinden satın almalarda
                          geçerli olacaktır.
                        </SubInfoText>
                      )}
                    </>
                  }
                </Form>
              </PanelArea>
              <PanelArea
                isMobile={isMobile}
                style={{ flexDirection: "column" }}
              >
                <CCForm
                  guid={user.guid}
                  packageId={"150"}
                  cammpaignCode={cammpaignCode}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "15px",
                    cursor: "pointer",
                  }}
                >
                  <Link
                    key={"dontsubscribe"}
                    to={`/home`}
                    style={{ textDecoration: "none" }}
                  >
                    <SubInfoText>Abone Olmadan Devam Et</SubInfoText>
                  </Link>
                </div>
              </PanelArea>
            </PanelContainer>
          </Col>
        </Row>

        <HooxModal
          isShowing={isShowingPackageSelection}
          hide={togglePackageSelectionModal}
          title="Paket Seçimi"
          type={HOOX_MODAL.INFO}
        >
          <p style={{ color: "#ffffff" }}>
            İlerlemek için bir paket seçmelisiniz.
          </p>
        </HooxModal>
        <HooxModal
          isShowing={isShowingFail}
          hide={toggleFailModal}
          title="İşlem Başarısız"
          type={HOOX_MODAL.INFO}
        >
          <p style={{ color: "#ffffff" }}>{failMessage}</p>
        </HooxModal>
      </div>
    </>
  );
}
export default Subscription;

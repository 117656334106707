import Axios from "../util/apiConfig";
import { GetNewToken } from "./contentService";

Axios.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("authToken");
  config.headers.Authorization = token;
  return config;
});

export async function getPackages() {
  return Axios.get("/cus/product")
    .then((result) => result.data)
    .catch((error) => {
      if (error.response.status === 401) {
        //GetNewToken();
      }
      return error;
    });
}

export async function changeUser(name, surname, city, mail, phone) {
  return Axios.put("cus/customer/info", {
    customerInfo: {
      city,
      name,
      gender: "Male",
      gsmno: phone,
      surname,
      email: mail,
    },
  })
    .then((response) => response)
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        GetNewToken();
      }
      return error;
    });
}

export async function finishSubscription() {
  return Axios.put("cus/subscriber/finish/subscription")
    .then((response) => response)
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        GetNewToken();
      }
      return error;
    });
}

export async function deleteAccount() {
  return Axios.delete("cus/account")
    .then((response) => response)
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        GetNewToken();
      }
      return error;
    });
}

export async function getSSS() {
  return Axios.get("cus/info/sss")
    .then((response) => response.data)
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        GetNewToken();
      }
      return error;
    });
}

export async function getAboutUs() {
  return Axios.get("cus/info/aboutus")
    .then((response) => response.data)
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
      }
      return error;
    });
}

// get profile images
export async function getProfileImages() {
  return Axios.get("cus/profiles/images")
    .then((response) => response.data)
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        GetNewToken();
      }
      return error;
    });
}

// add profile image
export async function addProfileImage(imageUrl) {
  return Axios.post("cus/profiles/images/add", imageUrl, {
    headers: { "Content-Type": "text/plain" },
  })
    .then((response) => response.data)
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        GetNewToken();
      }
      return error;
    });
}

export async function verifySubscription(packageId, customerGuid) {
  //return Axios.post("cus/subscriber/v2/verify/subscription", null, {
  return Axios.post("cus/subscriber/verify/subscription/v3", null, {
    params: {
      packageId: packageId,
      customerGuid: customerGuid,
    },
  })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {});
}

export async function validateSubscription(packageId, customerGuid) {
  return Axios.post("cus/subscriber/validate/subscription", null, {
    params: {
      packageId: packageId,
      customerGuid: customerGuid,
    },
  })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
      }
      return error;
    });
}

// get user messages
export async function getUserMessages() {
  return Axios.get("cus/messages")
    .then((response) => response.data)
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        //GetNewToken();
      }
      return error;
    });
}

export async function getContracts() {
  return Axios.get("/aut/auth/contracts")
    .then((result) => result.data)
    .catch((error) => {
      if (error.response.status === 401) {
        //GetNewToken();
      }
      return error;
    });
}

export async function getUserAggrements() {
  return Axios.get("/cus/info/cfbsk")
    .then((result) => result.data)
    .catch((error) => {
      if (error.response.status === 401) {
        //GetNewToken();
      }
      return error;
    });
}

export async function getUserAggrementsWithCode(code) {
  return Axios.get("/cus/info/cfbsk", { params: { code } })
    .then((result) => result.data)
    .catch((error) => {
      if (error.response.status === 401) {
        //GetNewToken();
      }
      return error;
    });
}

export async function getUserAggrementsForPayment(packageId, channel) {
  return Axios.get("/cus/info/dskle", { params: { packageId, channel } })
    .then((result) => result.data)
    .catch((error) => {
      if (error.response.status === 401) {
        //GetNewToken();
      }
      return error;
    });
}

export async function customerCreateV4(
  fullName,
  email,
  password,
  gsmNo,
  iytPermission
) {
  return Axios.post("/cs/customer/v4/psxvr", {
    email,
    password,
    name: fullName,
    gsmNo,
    iytPermission,
  })

    .then((response) => {
      /* if (response.data.code === 200) {
      } */
      return response;
    })
    .catch((error) => {
      /* if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        //GetNewToken();
      } */

      return error;
    });
}

export async function customerCreateV5(
  fullName,
  email,
  password,
  gsmNo,
  iytPermission
) {
  return Axios.post("/cs/customer/v5/psxvr", {
    email,
    password,
    name: fullName,
    gsmNo,
    iytPermission,
  })

    .then((response) => {
      /* if (response.data.code === 200) {
      } */
      return response;
    })
    .catch((error) => {
      /* if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        //GetNewToken();
      } */

      return error;
    });
}

export async function confirmEmail(code) {
  return Axios.get(`cus/customer/fklrt/${code}`)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        //GetNewToken();
      }
      return error;
    });
}

export async function iysRed(guid) {
  return Axios.get(`cus/customer/open/ikrl/${guid}`)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        //GetNewToken();
      }
      return error;
    });
}

export async function getCardList() {
  return Axios.get("/cus/payments/list/card")
    .then((result) => result.data)
    .catch((error) => {
      if (error.response.status === 401) {
        //GetNewToken();
      }
      return error;
    });
}

export async function renewSubscription(guid) {
  return Axios.get("/cus/subscriber/renew/subscription", { params: { guid } })
    .then((result) => result.data)
    .catch((error) => {
      //console.log("renewSubscription.fail:", error);

      return error;
    });
}

export async function checkCampaign(product, kdy) {
  return Axios.get("cus/campaigns/check", { params: { product, kdy } })
    .then((response) => response.data)
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        
      }
      return error;
    });
}

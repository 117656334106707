import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import ReactMarkdown from "react-markdown";
import useModal from "../hooks/useModal";
import {
  getUserAggrementsForPayment,
  verifySubscription,
} from "../../service/customerService.js";
import { apiUrl, paymentUrl } from "../../util/apiConfig.js";
import { months, years } from "../../util/constants.js";
import HooxModal, { HOOX_MODAL } from "../HooxModal.jsx";

const FormControl = styled(Form.Control)`
  background-color: rgba(70, 69, 69, 0.12) !important;
  border-radius: 7px !important;
  resize: none;
  height: 50px;
  font: normal normal normal 14px Montserrat;
  &:focus-visible {
    outline: rgba(0, 0, 0, 0.8) !important;
  }
  &::placeholder {
    color: rgba(
      253,
      253,
      253,
      0.72
    ); /* Set your desired placeholder color here */
    opacity: 1; /* Ensure the placeholder color is fully opaque */
  }
`;

/*
const ContentDiv = styled.div`
  color: #fff;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 20px;
  padding: 30px;
  font-size: 13px;
  width: 50%;
`;
*/

const CCCVVField = styled(Form.Control)`
  background-color: rgba(70, 69, 69, 0.12) !important;
  border-radius: 7px !important;
  resize: none;
  height: 50px;
  font: normal normal normal 14px Montserrat;
  &:focus-visible {
    outline: rgba(0, 0, 0, 0.8) !important;
  }
  &::placeholder {
    color: rgba(
      253,
      253,
      253,
      0.72
    ); /* Set your desired placeholder color here */
    opacity: 1; /* Ensure the placeholder color is fully opaque */
  }
`;

const CCSelect = styled.select`
  width: 40%;
  background-color: rgba(70, 69, 69, 0.12) !important;
  border-radius: 7px !important;
  resize: none;
  height: 50px;
  margin-right: 10px;
  font: normal normal normal 14px Montserrat;
  color: white;
  &:focus-visible {
    outline: rgba(0, 0, 0, 0.8) !important;
  }
  border: 2px solid rgb(17, 213, 223, 70%);!important;
`;

export default function CCForm(props) {
  // get params
  //const { guid, packageId } = useParams();

  const { isShowing: modalVisible, toggle: toggleModal } = useModal();
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const [isLoading, setIsLoading] = useState(false);
  const [contratContent, setContratContent] = useState("");
  const [contracts, setContracts] = useState(undefined);
  const { isShowing: isShowingContract, toggle: toggleContractModal } =
    useModal();
  const sContract = useRef(null);
  const pInfo = useRef(null);
  const userDect = localStorage.getItem("isUser");
  const navigate = useNavigate();

  const guid = props.guid;
  const packageId = props.packageId;
  const cmpCode = props.cammpaignCode;

  const [failMessage, setFailMessage] = useState(
    "Lütfen bütün alanları kontrol ediniz."
  );

  const submissionTriggeredRef = useRef(false); 

  //const queryParams = new URLSearchParams(window.location.search);
  //const cmpCode = queryParams.get("cmp");

  if (userDect !== "true") {
    //console.log("userDect:", userDect);
    navigate("/");
  }

  const handleSubmit = async (event) => {
    const formElement = event.currentTarget;
    event.preventDefault();

    if (submissionTriggeredRef.current) return;

    setFailMessage("");
    setIsLoading(true);

    const { holderName, cardNumber, expiryMonth, expiryYear, cvv } =
      Object.values(event.currentTarget.elements)
        .slice(0, 7)
        .reduce((acc, cur) => {
          acc[cur.name] = cur.value;
          return acc;
        }, {});

    // regex
    const nameRegex = /^[a-zA-Z0-9ğüşöçıİĞÜŞÖÇ ]{2,30}$/;
    const cardNumberRegex = /^(\d{4}[- ]?){3}\d{4}$/;
    const expiryMonthRegex = /^[0-9]{2}$/;
    const expiryYearRegex = /^[0-9]{2}$/;
    const cvvRegex = /^[0-9]{3}$/;

    //console.log("1:");

    let subscriptionValid = false;
    if (packageId && guid) {
      //console.log("2:");
      try {
        if (packageId && guid) {
          try {
            const response = await verifySubscription(packageId, guid);
            //console.log("3:");
            //console.log("verifySubscription.response:", response);
            if (response?.code === 200) {
              //console.log("4:");
              subscriptionValid = true;
            } else {
              //console.log("4.1:");
              setFailMessage(response.message);
            }
            //console.log("5:");
          } catch (error) {
            console.error("Subscription doğrulama hatası:", error);
            subscriptionValid = false;
          }
        }
      } catch (error) {
        //console.error("Subscription doğrulama hatası:", error);
        subscriptionValid = false;
      }
    }
    //console.log("6:");
    //console.log("subscriptionValid-1:", subscriptionValid);

    if (!subscriptionValid) {
      //console.log("7:");
      //event.preventDefault();
      setIsLoading(false);
      toggleModal();
      return;
    }

    //console.log("8:");
    //console.log("subscriptionValid:", subscriptionValid);
    if (
      !nameRegex.test(holderName) ||
      !cardNumberRegex.test(cardNumber) ||
      !expiryMonthRegex.test(expiryMonth) ||
      !expiryYearRegex.test(expiryYear) ||
      !cvvRegex.test(cvv)
    ) {
      //event.preventDefault();
      setIsLoading(false);
      setFailMessage("Lütfen bütün alanları kontrol ediniz.");
      toggleModal();
      return;
    }

    //console.log("cmpCode:", cmpCode)
    
    submissionTriggeredRef.current = true;
    formElement.submit();

  };

  const [cardNumber, setCardNumber] = useState("");

  const [holderName, setHolderName] = useState("");

  const handleCardNumberChange = (e) => {
    // Sadece sayıları ve boşlukları tutacak şekilde formatlayın
    let formattedCardNumber = e.target.value.replace(/[^\d\s]/g, "");

    // Boşlukları ekleyerek dört grup oluşturun
    formattedCardNumber = formattedCardNumber.replace(/(\d{4})(?=\d)/g, "$1 ");

    formattedCardNumber = formattedCardNumber.slice(0, 19); // Maksimum 19 karakter (16 rakam ve 3 boşluk)
    e.target.value = formattedCardNumber;
    // State'i güncelle
    setCardNumber(formattedCardNumber);
  };

  const [securityCode, setSecurityCode] = useState("");

  const handleSecurityCodeChange = (e) => {
    // Sadece sayıları tutacak şekilde formatlayın
    let formattedSecurityCode = e.target.value.replace(/[^\d]/g, "");

    // Kodu maksimum 3 karakterle sınırlayın
    formattedSecurityCode = formattedSecurityCode.slice(0, 3);

    e.target.value = formattedSecurityCode;
    // State'i güncelle
    setSecurityCode(formattedSecurityCode);
  };

  const textInput = (event) => {
    const { value } = event.target;

    if (/^\d$/.test(value[value.length - 1])) {
      event.target.value = value.slice(0, value.length - 1);
    }
    setHolderName(event.target.value);
  };

  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  function handleMonthChange(event) {
    setMonth(event.target.value);
  }

  function handleYearChange(event) {
    setYear(event.target.value);
  }

  function ModelContent(data) {
    setContratContent(data);
    toggleContractModal();
  }

  useEffect(() => {
    if (contracts === undefined) {
      getUserAggrementsForPayment(packageId, "Kredi Kartı") //getContracts()
        .then((response) => {
          setContracts(response.data);
        })
        .catch((error) => console.log(error));
    }
  }, [contracts]);

  /*
  const verifyForSubscription = () => {
    console.log("verifyForSubscription:")
      // add verify Subscription
      if (packageId && guid) {
        verifySubscription(packageId, guid).then((response) => {
          console.log("verifySubscription.response:", response)
          if (response?.code === 200) {
            setValidation(true)

            const paymentInfo = {
              customerGuid: guid,
              packageId: packageId,
              cmpCode: cmpCode,
              holderName: holderName,
              cardNumber: cardNumber,
              expiryMonth: month,
              expiryYear: year,
              cvv: "000",
            };
    
            // response 200 ise ödeme ekranı route'una yönlendiriyoruz
            //navigate('/pym', { state: paymentInfo });


          } else {
            setValidation(false)
            //setFailMessage(response?.message);
            //toggleFailModal();
          }
        });
      } else {
        //togglePackageSelectionModal();
      }
    };
  */

  return (
    <div
      style={{
        minHeight: isMobile ? "" : "10vh",
      }}
    >
      <div className="container" style={{ marginBottom: "1rem", 
        display: "flex",
        justifyContent: "center", // Yatay hizalama
        alignItems: "center", // Dikey hizalama
        paddingLeft: isMobile ? "1px":"20px",
        paddingRight: isMobile ? "1px":"20px"
       }}>
        <Row
          style={{ paddingTop: isMobile ? "40px" : "1px", width: isMobile ? "100%":"70%"
           }}
        >
          <Form
            action={apiUrl + paymentUrl}
            method="post"
            encType="multipart/form-data"
            style={{ marginTop: "20px" }}
            onSubmit={handleSubmit}
          >
            <Form.Group className="mb-3">
              <FormControl
                id="name"
                name="holderName"
                placeholder="İsim Soyisim"
                //onChange={textInput}
                onChange={(event) => textInput(event)}
                value={holderName}
                required
              />
            </Form.Group>

            {/** Bu bolume eklenen hidden alanlar form alani hatasi veriyor. required false olanlar en alta eklenecek.  */}
            <input type="hidden" name="customerGuid" value={guid} />
            <input type="hidden" name="packageId" value={packageId} />

            <Form.Group className="mb-3">
              <FormControl
                id="kkno"
                name="cardNumber"
                placeholder="Kredi Kartı Numarası"
                onChange={(event) => handleCardNumberChange(event)}
                value={cardNumber}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <div style={{ display: "flex" }}>
                <CCSelect
                  value={month}
                  onChange={handleMonthChange}
                  id="expiryMonth"
                  name="expiryMonth"
                  required
                >
                  {months.map((month) => (
                    <option key={month.value} value={month.value}>
                      {month.label}
                    </option>
                  ))}
                </CCSelect>

                <CCSelect
                  value={year}
                  onChange={handleYearChange}
                  id="expiryYear"
                  name="expiryYear"
                  required
                >
                  {years.map((year) => (
                    <option key={year.value} value={year.value}>
                      {year.label}
                    </option>
                  ))}
                </CCSelect>

                <CCCVVField
                  id="cvv"
                  name="cvv"
                  placeholder="CVV"
                  onChange={(event) => handleSecurityCodeChange(event)}
                  value={securityCode}
                  required
                />
              </div>
            </Form.Group>

            <Row style={{ marginTop: "10px" }}>
              <Col sm="1">
                <label className="custom-checkbox">
                  <input required type="checkbox" ref={sContract} />
                  <span className="checkmark"></span>
                </label>
              </Col>
              <Col sm="11">
                <label
                  onClick={() => ModelContent(contracts?.sellingContract)}
                  style={{
                    color: "#cccccc",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  Mesafeli Satış ve Üyelik Sözleşmesi
                </label>{" "}
                'ni okudum, onaylıyorum.
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Col sm="1">
                <label className="custom-checkbox">
                  <input required type="checkbox" ref={pInfo} />
                  <span className="checkmark"></span>
                </label>
              </Col>
              <Col sm="11">
                <label
                  onClick={() => ModelContent(contracts?.preliminaryInfo)}
                  style={{
                    color: "#cccccc",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  Ön Bilgilendirme
                </label>{" "}
                'yi okudum, onaylıyorum.
              </Col>
            </Row>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                className="btn btn-primary checkOutButton w-100"
                type="submit"
                //onClick={(event) => checkFormAnfcontinue(event)}
                style={{
                  marginTop: "15px",
                  width: "50%",
                  display: "flex",
                  justifyContent: "center",
                }}
                disabled={isLoading}
              >
                {isLoading ? <div className="spinner"></div> : "ÖDEME YAP"}
              </Button>
            </div>
            <input type="hidden" name="deviceTypeParam" value="Web" />
            <input
              type="hidden"
              name="tkn"
              value={localStorage.getItem("authToken")}
            />
            <input type="hidden" name="cmpCode" value={cmpCode} />
          </Form>
        </Row>
        <HooxModal
          isShowing={modalVisible}
          hide={toggleModal}
          title={failMessage}
          type={HOOX_MODAL.ERROR}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button variant="secondary" onClick={toggleModal}>
              Kapat
            </Button>
          </div>
        </HooxModal>

        <HooxModal
          isShowing={isShowingContract}
          hide={toggleContractModal}
          type={HOOX_MODAL.TEXT}
        >
          <ReactMarkdown>{contratContent}</ReactMarkdown>
          <Button variant="secondary" onClick={toggleContractModal}>
            Kapat
          </Button>
        </HooxModal>
      </div>
    </div>
  );
}

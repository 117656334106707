import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  AccordionContext,
  Button,
  Col,
  Form,
  FormControl,
  Modal,
  ModalBody,
  ModalFooter,
  ModalTitle,
  Row,
} from "react-bootstrap";
import { createPortal } from "react-dom";
import { InfoField, SubsFormButtons } from "./MySubscription";
import styled from "styled-components";
import { useNavigate } from "react-router";
import { apiUrl, paymentUrl } from "../../util/apiConfig";
import { useMediaQuery } from "react-responsive";
import useModal from "../hooks/useModal";
import { months, years } from "../../util/constants";
import Accordion from "react-bootstrap/Accordion";
import { renewSubscription } from "../../service/customerService";
import { useAuth } from "../AuthContext";
import { yearsToQuarters } from "date-fns";
import HooxModal, { HOOX_MODAL } from "../HooxModal";

export const ModalButtons = styled(`Button`)`
  font-family: GilroyLight;
  background-color: transparent;
  padding: 10px;
  border-radius: 7px;
  border: 1px solid rgba(18 214 223 / 30%);
  &:hover {
    background-color: #bface282;
  }

  &:disabled {
    background-color: #40456b;
    border: 1px solid rgb(216 219 223 / 35%);
    color: rgb(216 219 223 / 35%);
  }
`;

const Line = styled("hr")`
  color: #12d6df;
  opacity: 1;
`;

const InfoText = styled(`span`)`
  font-size: 14px;
  font-family: GilroyLight;
  color: #ffffffa3;
  letter-spacing: 0.04rem;
`;

const CCSelect = styled.select`
  width: 30%;
  background-color: #ffffff40 !important;
  margin-right: 10px;
  font: normal normal normal 14px GilroyLight;
  color: white;
  &:focus-visible {
    outline: rgba(0, 0, 0, 0.8) !important;
    color: gray;
  }

  display: block;
  border-radius: 7px !important;
  border: 1px solid rgba(18 214 223 / 30%);
`;

const CCDateField = styled(Form.Control)`
  font-family: GilroyLight;
  background-color: #ffffff40 !important;
  display: block;
  border-radius: 7px !important;
  border: 1px solid rgba(18 214 223 / 30%);
  width: 100%;
  padding: 10px;
`;

const CCNameField = styled(Form.Control)`
  font-family: GilroyLight;
  background-color: #ffffff40 !important;
  display: block;
  border-radius: 7px !important;
  border: 1px solid rgba(18 214 223 / 30%);
  width: 100%;
  padding: 10px;
`;

const CardListDialog = ({ isShowing, hide, data, userDetails }) => {
  const [formData, setFormData] = useState({
    // Burada gerekli form verileri için state tanımlayabilirsiniz.
  });
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const [isLoading, setIsLoading] = useState(false);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [hideAccordion, setHideAccordion] = useState(false);
  const [paymentWithOldCardDisabled, setPaymentWithOldCardDisabled] =
    useState(false);
  const [paymentWithNewCardDisabled, setPaymentWithNewCardDisabled] =
    useState(false);
  const [paymentMessage, setPaymentMessage] = useState("");
  const { isShowing: modalVisible, toggle: toggleModal } = useModal();
  const [formMessage, setFormMessage] = useState("");

  const { user, setUser } = useAuth();

  // Form submit işlemi
  const repayWithSameCard = (event) => {
    event.preventDefault();
    setPaymentProcessing(true);
    setPaymentWithOldCardDisabled(true);
    setHideAccordion(true);

    renewSubscription(user.guid).then((res) => {
      //console.log(res);
      if (res.code === 200) {
        setPaymentMessage(res.message);
        setPaymentSuccess(true);
      }
      setPaymentProcessing(false);
    });

    /*
    setTimeout(() => {
      setPaymentMessage("Ödeme Tamam");
      setPaymentProcessing(false);
      setPaymentWithOldCardDisabled(false);
      setHideAccordion(false);
    }, 3000);

    */
  };

  const repayWithNewCard = (eventKey) => {
    //setPaymentProcessing(true);
  };

  const handleAccordion = (eventKey) => {
    if (eventKey === "0") {
      setPaymentWithOldCardDisabled(true);
    } else {
      setPaymentWithOldCardDisabled(false);
    }
  };

  const handleSubmit = (event) => {
    console.log("handleSubmit called- event:", event);
    setIsLoading(true);
    setPaymentWithNewCardDisabled(true);
    setFormMessage("");

    /*
    setTimeout(() => {
      setIsLoading(false);
      setPaymentWithNewCardDisabled(false);
    }, 3000);
    */

    const holderName = event.currentTarget.elements["holderName"].value;
    const cardNumber = event.currentTarget.elements["cardNumber"].value;
    const expiryMonth = event.currentTarget.elements["expiryMonth"].value;
    const expiryYear = event.currentTarget.elements["expiryYear"].value;
    const cvv = event.currentTarget.elements["cvv"].value;

    /*
    const { holderName, cardNumber, expiryMonth, expiryYear, cvv } =
      Object.values(event.currentTarget.elements)
        .slice(0, 7)
        .reduce((acc, cur) => {
          acc[cur.name] = cur.value;
          return acc;
        }, {});
        */

    console.log("Object.values.cvv:", cvv);
    // name regex
    const nameRegex = /^[a-zA-Z0-9ğüşöçıİĞÜŞÖÇ ]{2,30}$/;

    // card number regex
    const cardNumberRegex = /^(\d{4}[- ]?){3}\d{4}$/;

    // expiry month regex
    const expiryMonthRegex = /^[0-9]{2}$/;

    // expiry year regex
    const expiryYearRegex = /^[0-9]{2}$/;

    // cvv regex
    const cvvRegex = /^[0-9]{3}$/;
    if (
      !nameRegex.test(holderName) ||
      !cardNumberRegex.test(cardNumber) ||
      !expiryMonthRegex.test(expiryMonth) ||
      !expiryYearRegex.test(expiryYear) ||
      !cvvRegex.test(cvv)
    ) {
      console.log("holderName:", holderName);
      console.log("expiryMonth:", expiryMonth);
      console.log("cvv:", cvv);
      console.log("cvvRegex.test(cvv):", cvvRegex.test(cvv));

      event.preventDefault();
      //toggleModal();
      setFormMessage("Lütfen tüm alanları kontrol ediniz. ");
      setPaymentWithNewCardDisabled(false);
      setPaymentProcessing(false);
      setIsLoading(false);
      return;
    }
    console.log("handleSubmit end");
  };

  const textInput = (event) => {
    const { value } = event.target;

    if (/^\d$/.test(value[value.length - 1])) {
      event.target.value = value.slice(0, value.length - 1);
    }
  };

  const [cardNumber, setCardNumber] = useState("");
  const [securityCode, setSecurityCode] = useState("");

  const handleCardNumberChange = (e) => {
    // Sadece sayıları ve boşlukları tutacak şekilde formatlayın
    let formattedCardNumber = e.target.value.replace(/[^\d\s]/g, "");

    // Boşlukları ekleyerek dört grup oluşturun
    formattedCardNumber = formattedCardNumber.replace(/(\d{4})(?=\d)/g, "$1 ");

    formattedCardNumber = formattedCardNumber.slice(0, 19); // Maksimum 19 karakter (16 rakam ve 3 boşluk)
    e.target.value = formattedCardNumber;
    // State'i güncelle
    setCardNumber(formattedCardNumber);
  };

  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  function handleMonthChange(event) {
    setMonth(event.target.value);
  }

  function handleYearChange(event) {
    setYear(event.target.value);
  }

  const handleSecurityCodeChange = (e) => {
    // Sadece sayıları tutacak şekilde formatlayın
    let formattedSecurityCode = e.target.value.replace(/[^\d]/g, "");

    // Kodu maksimum 3 karakterle sınırlayın
    formattedSecurityCode = formattedSecurityCode.slice(0, 3);

    e.target.value = formattedSecurityCode;
    // State'i güncelle
    setSecurityCode(formattedSecurityCode);
    console.log("formattedSecurityCode:", formattedSecurityCode);
  };

  const hideModal = () => {
    setPaymentMessage("");
    setPaymentWithNewCardDisabled(false);
    setPaymentWithOldCardDisabled(false);
    setPaymentProcessing(false);
    setIsLoading(false);
    hide();
  };

  const closeDialog = () => {
    setPaymentMessage("");
    setPaymentWithNewCardDisabled(false);
    setPaymentWithOldCardDisabled(false);
    setPaymentProcessing(false);
    setIsLoading(false);
    navigate("/home");
    //hide();
  };

  return (
    isShowing &&
    createPortal(
      <Fragment>
        <Modal
          show={isShowing}
          onHide={hideModal}
          style={{ color: "white", backgroundColor: "#0000005c" }}
          centered
        >
          <ModalTitle
            style={{
              fontWeight: "700",
              fontFamily: "GilroyLight",
              padding: "20px",
              textAlign: "center",
              letterSpacing: "0.04rem",
            }}
          >
            ÖDEME YENİLEME
            <Line></Line>
          </ModalTitle>
          <ModalBody>
            <div>
              <InfoField style={{ backgroundColor: "#ffffff40" }}>
                **** **** **** {data[0]?.last_4 || " - "}
              </InfoField>
              <Button
                className="btn btn-primary checkOutButton"
                style={{
                  fontFamily: "GilroyLight",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
                type="button"
                onClick={repayWithSameCard}
                disabled={paymentWithOldCardDisabled}
              >
                {paymentProcessing ? (
                  <div className="spinner"></div>
                ) : (
                  "Kayıtlı Kartla Öde"
                )}
              </Button>
              {paymentMessage ? (
                <>
                  <div style={{ textAlign: "center", paddingTop: "20px" }}>
                    {paymentMessage}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "20px",
                    }}
                  >
                    <SubsFormButtons
                      className="btn btn-primary cancelButton"
                      style={{
                        fontFamily: "GilroyLight",
                        width: "40%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                      type="button"
                      onClick={closeDialog}
                    >
                      Tamam
                    </SubsFormButtons>
                  </div>
                </>
              ) : (
                ""
              )}
              <br />
            </div>
            <Line
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                display: paymentWithOldCardDisabled ? "none" : "block",
              }}
            />

            <Accordion
              flush
              style={hideAccordion ? { display: "none" } : {}}
              onSelect={handleAccordion}
            >
              <Accordion.Item
                eventKey="0"
                style={{ backgroundColor: "transparent" }}
              >
                <Accordion.Header style={{ backgroundColor: "transparent" }}>
                  Yeni Kart Ekle
                </Accordion.Header>
                <Accordion.Body>
                  <div style={isMobile ? { width: "85%" } : {}}>
                    <Form
                      action={apiUrl + paymentUrl} 
                      method="post"
                      encType="multipart/form-data"
                      style={{ marginTop: "10px" }}
                      onSubmit={handleSubmit}
                    >
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="name">
                          Kart Üzerindeki İsim
                        </Form.Label>
                        <CCNameField
                          id="name"
                          name="holderName"
                          placeholder="İsim Soyisim"
                          onChange={textInput}
                          required
                        />
                      </Form.Group>

                      <input
                        type="hidden"
                        name="customerGuid"
                        value={user.guid}
                      />
                      <input
                        type="hidden"
                        name="packageId"
                        value={userDetails.product.externalId}
                      />
                      <input type="hidden" name="changeCard" value={1} />
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="kkno">
                          Kredi kartı Numarası
                        </Form.Label>
                        <CCNameField
                          id="kkno"
                          name="cardNumber"
                          placeholder="0000 0000 0000 0000"
                          onChange={(event) => handleCardNumberChange(event)}
                          value={cardNumber}
                          required
                        />
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="expiryMonth">
                          Son Kullanma Tarihi
                        </Form.Label>
                        <div style={{ display: "flex" }}>
                          <CCSelect
                            value={month}
                            onChange={handleMonthChange}
                            id="expiryMonth"
                            name="expiryMonth"
                            required
                          >
                            {months.map((month) => (
                              <option key={month.value} value={month.value}>
                                {month.label}
                              </option>
                            ))}
                          </CCSelect>

                          <CCSelect
                            value={year}
                            onChange={handleYearChange}
                            id="expiryYear"
                            name="expiryYear"
                            required
                          >
                            {years.map((year) => (
                              <option key={year.value} value={year.value}>
                                {year.label}
                              </option>
                            ))}
                          </CCSelect>

                          <CCDateField
                            id="cvv"
                            name="cvv"
                            placeholder="CVV"
                            onChange={(event) =>
                              handleSecurityCodeChange(event)
                            }
                            value={securityCode}
                            required
                          />
                        </div>
                      </Form.Group>

                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          className="btn btn-primary checkOutButton w-100"
                          type="submit"
                          style={{
                            marginTop: "15px",
                            width: "50%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                          disabled={paymentWithNewCardDisabled}
                        >
                          {isLoading ? (
                            <div className="spinner"></div>
                          ) : (
                            "Yeni Kartla Öde"
                          )}
                        </Button>
                      </div>
                      <div>{formMessage}</div>
                      <input type="hidden" name="deviceTypeParam" value="Web" />
                    </Form>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Line />

            {/* 
            <InfoField style={{ backgroundColor: "#ffffff40" }}>
              {data[0]?.c_name || " - "}
            </InfoField>           
            <InfoField style={{ backgroundColor: "#ffffff40" }}>
              {data[0]?.month || " - "}/{data[0]?.year || " - "}
            </InfoField>
            */}

            <Row>
              <Col sm={8}>
                <InfoText>
                  Kredi kartı bilgileriniz yetkili ödeme sistemi üzerinde
                  güvenli şekilde saklanmaktadır.
                </InfoText>
              </Col>
              <Col style={{ textAlign: "right" }}>
                <img
                  src="/assets/img/master300x200.png"
                  style={{ width: "40px", marginRight: "3px" }}
                />
                <img
                  src="/assets/img/visa300x200.png"
                  style={{ width: "40px", marginRight: "3px" }}
                />
              </Col>
            </Row>
          </ModalBody>
        </Modal>

        <HooxModal
          isShowing={modalVisible}
          hide={toggleModal}
          title={"Lütfen bütün alanları kontrol ediniz."}
          type={HOOX_MODAL.ERROR}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button variant="secondary" onClick={toggleModal}>
              Kapat
            </Button>
          </div>
        </HooxModal>
      </Fragment>,
      document.body
    )
  );
};
export default CardListDialog;

import React, { useEffect, useRef, useState } from "react";
import { apiUrl, paymentUrl } from "../../util/apiConfig";
import { useLocation } from "react-router";
import { useMediaQuery } from "react-responsive";
import { Col, Container } from "react-bootstrap";

export default function CCPayment() {
  const formRef = useRef(null);
  //const iframeName = "paymentFrame"; // Form target'ı ile eşleşecek
  //const [showDialog, setShowDialog] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const location = useLocation();
  const {
    customerGuid,
    packageId,
    cmpCode,
    holderName,
    cardNumber,
    expiryMonth,
    expiryYear,
    cvv,
  } = location.state || {};

  useEffect(() => {
    console.log("holderName:", holderName);
    // Tüm gerekli bilgiler doluysa formu otomatik submit ediyoruz
    if (holderName && cardNumber && expiryMonth && expiryYear && cvv) {
      if (formRef.current) {
        //formRef.current.submit();
      }
    }
  }, [holderName, cardNumber, expiryMonth, expiryYear, cvv]);

  return (
    <>
      {/* Bu form görünür olmayacak. Sadece gerekli alanları ve hidden inputları içeriyor. */}

      <div
        className="container"
        style={{ marginTop: isMobile ? "" : "15vh", marginBottom: "20vh" }}
      >
        <Container>
          <Col style={{ textAlign: "center" }}>
            <h1
              style={{
                fontSize: isMobile ? "2em" : "3em",
                marginBottom: "20px",
                marginTop: isMobile ? "20%" : "30%",
                color: "#12d6df",
              }}
            >
              Ödeme işleminiz yapılıyor...
            </h1>
          </Col>
        </Container>
      </div>
      <form
        ref={formRef}
        //action={apiUrl + paymentUrl}
        //method="post"
        //encType="multipart/form-data"
        style={{ display: "none" }}
        //target={iframeName}
      >
        <input type="hidden" name="holderName" value={holderName} />
        <input type="hidden" name="cardNumber" value={cardNumber} />
        <input type="hidden" name="expiryMonth" value={expiryMonth} />
        <input type="hidden" name="expiryYear" value={expiryYear} />
        <input type="hidden" name="cvv" value={cvv} />
        <input type="hidden" name="customerGuid" value={customerGuid} />
        <input type="hidden" name="packageId" value={packageId} />
        <input type="hidden" name="cmpCode" value={cmpCode} />
        <input type="hidden" name="deviceTypeParam" value="Web" />
        <input
          type="hidden"
          name="tkn"
          value={localStorage.getItem("authToken")}
        />
      </form>
    </>
  );
}

export const USER_RIGHT_NOT_IN_PACKAGE = 0;
export const USER_RIGHT_IN_PACKAGE = 1;
export const USER_RIGHT_BUY_PACKAGE = 2;

export const isMobile = "420px";
export const isMiddle = "800px";

export const sizes = {
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
  "2xl": "1536px",
};

export const POSTER_WEB_BANNER = 3;
export const POSTER_WEB_DETAIL = 9;
export const POSTER_WEB_LIST = 8;
export const POSTER_MOBILE_DETAIL = 1;

export const months = [
  { value: "", label: "Ay" },
  { value: "01", label: "01" },
  { value: "02", label: "02" },
  { value: "03", label: "03" },
  { value: "04", label: "04" },
  { value: "05", label: "05" },
  { value: "06", label: "06" },
  { value: "07", label: "07" },
  { value: "08", label: "08" },
  { value: "09", label: "09" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
];

export const years = [
  { value: "", label: "Yıl" },
  { value: "25", label: "25" },
  { value: "26", label: "26" },
  { value: "27", label: "27" },
  { value: "28", label: "28" },
  { value: "29", label: "29" },
  { value: "30", label: "30" },
  { value: "31", label: "31" },
  { value: "32", label: "32" },
  { value: "33", label: "33" },
];

export const City = function (id, name) {
  this.id = id;
  this.name = name;
};

export const cities = [
  new City("34", "İstanbul"),
  new City("06", "Ankara"),
  new City("35", "İzmir"),
  new City("01", "Adana"),
  new City("02", "Adıyaman"),
  new City("03", "Afyonkarahisar"),
  new City("04", "Ağrı"),
  new City("05", "Amasya"),
  new City("07", "Antalya"),
  new City("08", "Artvin"),
  new City("09", "Aydın"),
  new City("10", "Balıkesir"),
  new City("11", "Bilecik"),
  new City("12", "Bingöl"),
  new City("13", "Bitlis"),
  new City("14", "Bolu"),
  new City("15", "Burdur"),
  new City("16", "Bursa"),
  new City("17", "Çanakkale"),
  new City("18", "Çankırı"),
  new City("19", "Çorum"),
  new City("20", "Denizli"),
  new City("21", "Diyarbakır"),
  new City("22", "Edirne"),
  new City("23", "Elazığ"),
  new City("24", "Erzincan"),
  new City("25", "Erzurum"),
  new City("26", "Eskişehir"),
  new City("27", "Gaziantep"),
  new City("28", "Giresun"),
  new City("29", "Gümüşhane"),
  new City("30", "Hakkari"),
  new City("31", "Hatay"),
  new City("32", "Isparta"),
  new City("33", "Mersin"),
  new City("36", "Kars"),
  new City("37", "Kastamonu"),
  new City("38", "Kayseri"),
  new City("39", "Kırklareli"),
  new City("40", "Kırşehir"),
  new City("41", "Kocaeli"),
  new City("42", "Konya"),
  new City("43", "Kütahya"),
  new City("44", "Malatya"),
  new City("45", "Manisa"),
  new City("46", "Kahramanmaraş"),
  new City("47", "Mardin"),
  new City("48", "Muğla"),
  new City("49", "Muş"),
  new City("50", "Nevşehir"),
  new City("51", "Niğde"),
  new City("52", "Ordu"),
  new City("53", "Rize"),
  new City("54", "Sakarya"),
  new City("55", "Samsun"),
  new City("56", "Siirt"),
  new City("57", "Sinop"),
  new City("58", "Sivas"),
  new City("59", "Tekirdağ"),
  new City("60", "Tokat"),
  new City("61", "Trabzon"),
  new City("62", "Tunceli"),
  new City("63", "Şanlıurfa"),
  new City("64", "Uşak"),
  new City("65", "Van"),
  new City("66", "Yozgat"),
  new City("67", "Zonguldak"),
  new City("68", "Aksaray"),
  new City("69", "Bayburt"),
  new City("70", "Karaman"),
  new City("71", "Kırıkkale"),
  new City("72", "Batman"),
  new City("73", "Şırnak"),
  new City("74", "Bartın"),
  new City("75", "Ardahan"),
  new City("76", "Iğdır"),
  new City("77", "Yalova"),
  new City("78", "Karabük"),
  new City("79", "Kilis"),
  new City("80", "Osmaniye"),
  new City("81", "Düzce"),
];

export const subjects = ["Öneri", "Abonelik", "Ödeme", "İçerik", "Şikayet"];


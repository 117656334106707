import {
  Carousel,
  Col,
  Container,
  OverlayTrigger,
  Placeholder,
  Popover,
  Row,
  Stack,
  Button,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import React, { useState, useEffect } from "react";
import { addFavorite, removeFavorite } from "../service/contentService";
import { ContentWrapper, HooxButton } from "./commons/styled-bootstrap";
import styled from "styled-components";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import {
  POSTER_MOBILE_DETAIL,
  POSTER_WEB_DETAIL,
  USER_RIGHT_BUY_PACKAGE,
  USER_RIGHT_IN_PACKAGE,
} from "../util/constants";
import { checkEmptyObject } from "../util/checkEmptyObject";
import { useAuth } from "./AuthContext";
import useModal from "./hooks/useModal";
import HooxModal, { HOOX_MODAL } from "./HooxModal";
import { useMediaQuery } from "react-responsive";
import rtukSymbols from "../util/rtukSymbols";
import ContentVideo from "../pages/content/ContentVideo";
import VideoPlayer from "./VideoPlayerComp"; // silince player kuculuyor
import MatomoTracking from "./MatomoTracking";

const ContentButtonGroup = styled.div`
  & button:first-child {
    margin-left: 0;
  }
  & button {
    margin: 0 5px;
  }
`;

const ContentOverlay = styled.div`
  height: 100%;
  position: absolute;
  background: linear-gradient(0deg, rgba(0 0 0) 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 1;
`;

const ContentMainTitle = styled.div`
  color: #ffffff;
  font-family: "GilroyMedium";
  font-size: ${(props) => (props.isMobile ? "25" : "84")}px;
  font-weight: bold;
  margin-bottom: ${(props) => (props.isMobile ? "0" : "20")}px;
  box-sizing: content-box;
  line-height: 110%;
`;

const ContentSubData = styled.div`
  display: inline !important;
  color: #ffffff;
  font-family: "GilroyLight";
  font-size: 25px;
  margin-bottom: 20px;
  box-sizing: content-box;
`;

const ContentSubDataDefinition = styled.div`
  display: inline !important;
  color: #ffffff;
  font-family: "GilroyLight";
  font-size: 20px;
  margin-bottom: 20px;
  box-sizing: content-box;
  border: 2px #bb00bb solid;
  border-radius: 20px;
  padding: 1px 5px 2px 5px;
  text-align: right !important;
`;

const ContentSynopsis = styled.div`
  margin-top: ${(props) => (props.isMobile ? "3%" : "7%")};
  font-weight: 500;
  line-height: 120%;
  font-size: ${(props) => (props.isMobile ? "18" : "20")}px;
  font-family: "GilroyLight";
  display: block;
  color: white;
`;

function ContentDetailBanner({ content, trailer }) {
  const [player, setPlayer] = useState(null);
  const [isPaused, setIsPaused] = useState(false);
  const [isMuted, setIsMuted] = useState(true);

  const { user } = useAuth();

  const { isLoggedIn, guid } = user;

  const guid2 = localStorage.getItem("guid");

  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });

  const { usrRight, fav } = content;

  const [isFavorited, setIsFavorited] = useState(undefined);
  const [symbols, setSymbols] = useState([]);

  const navigation = useNavigate();

  const { isShowing: isShowingSuccess, toggle: toggleSuccessModal } =
    useModal();

  const { isShowing: isShowingLogin, toggle: toggleSuccessLogin } = useModal();

  useEffect(() => {
    setIsFavorited(fav);
    //console.log("rtuk", rtukSymbols(content.parental, content.rtukRating))
    setSymbols(rtukSymbols(content.parental, content.rtukRating));
  }, [fav]);

  const handleFavoriteClick = (e) => {
    // TODO store handle
    if (!content.fav) {
      addFavorite(content.id).then((response) => {
        setIsFavorited(!isFavorited);
      });
    }
    if (content.fav) {
      // TODO store handle
      removeFavorite(content.id).then((response) => {
        setIsFavorited(!isFavorited);
      });
    }
  };

  const getPoster = (posters = []) => {
    const poster = posters.find((poster) =>
      isMobile
        ? poster.code === POSTER_MOBILE_DETAIL
        : poster.code === POSTER_WEB_DETAIL
    );
    return poster ? poster.url : "404.img";
  };

  const shareButtonSize = 48;
  const windowSize = {
    w: 750,
    h: 600,
  };

  const sharePopover = (
    <Popover id="share-popover">
      <Popover.Body>
        <FacebookShareButton url={String(window.location)}>
          <FacebookIcon size={shareButtonSize} />
        </FacebookShareButton>

        <TwitterShareButton url={String(window.location)}>
          <TwitterIcon size={shareButtonSize} />
        </TwitterShareButton>

        <WhatsappShareButton
          url={String(window.location)}
          separator=":: "
          windowWidth={windowSize.w}
          windowHeight={windowSize.h}
        >
          <WhatsappIcon size={shareButtonSize} />
        </WhatsappShareButton>

        <LinkedinShareButton
          url={String(window.location)}
          windowWidth={windowSize.w}
          windowHeight={windowSize.h}
        >
          <LinkedinIcon size={shareButtonSize} />
        </LinkedinShareButton>
      </Popover.Body>
    </Popover>
  );

  const handleWatch = (e) => {
    //console.log("usrRight:" + usrRight)
    trailer.onPlayVideo(true);
    if (isLoggedIn) {
      if (usrRight === USER_RIGHT_IN_PACKAGE) {
        navigation("play");
      } else {
        toggleSuccessModal();
        // modal a buton konulacak.
        // Modal açıp abonelik başlatmalısınız mesajı verilecek. OK derse alttkai paket sayfasına yönlendirilecek. Cancel derse sayfada kalacak.
        //navigation('/user/product-selection/' + guid)
      }
    } else {
      toggleSuccessLogin(); // modal a buton konulacak.
      //navigation('/user/login')
    }
  };

  const mutePlayer = () => {
    player.video.muted = !isMuted;
    setIsMuted((prev) => !prev);
  };

  const playPlayer = () => {
    isPaused ? player.video.play() : player.video.pause();
    setIsPaused((prev) => !prev);
  };
  /*
  useEffect(() => {
    if (player && player.video) {
      player.video.muted = true;
    }
  }, [player?.video]);
  */

  return !checkEmptyObject(content) ? (
    <ContentWrapper>
      <MatomoTracking />
      <Carousel controls={false} indicators={false}>
        <Carousel.Item key={content.id}>
          <ContentOverlay className="d-block w-100" style={{ zIndex: "1" }} />
          {content.trailerPlayerOKey === null ||
          content.trailerPlayerPKey === null ? (
            <img
              className="bd-placeholder-img"
              style={{ height: "auto", width: "100%" }}
              src={getPoster(content.posters)}
              alt=""
            />
          ) : (
            <ContentVideo
              content={content}
              setPlayer={setPlayer}
              isTrailerPlay={trailer.isTrailerPlay}
            />
          )}
          <div
            style={{
              position: "absolute",
              bottom: "10px",
              right: "10px",
              zIndex: "9999",
            }}
          ></div>

          <HooxModal
            isShowing={isShowingSuccess}
            title="Abonelik İşlemi Gerekli"
            type={HOOX_MODAL.INFO}
          >
            <p style={{ color: "#ffffff" }}>
              İçeriği izlemek için abonelik başlatmalı veya paket
              yükseltmelisiniz.
            </p>
            <Button
              variant="secondary"
              onClick={() => navigation("/abonelik/paketler")}
              style={{
                marginRight: "10px",
                backgroundColor: "rgb(187, 0, 187)",
              }}
            >
              Tamam
            </Button>
            <Button variant="secondary" onClick={toggleSuccessModal}>
              Kapat
            </Button>
          </HooxModal>
          <HooxModal
            isShowing={isShowingLogin}
            title="Giriş Yapmalısınız"
            type={HOOX_MODAL.INFO}
          >
            <p style={{ color: "#ffffff", marginBottom: "60px" }}>
              İçeriği izlemek için giriş yapmalısınız.
            </p>
            <Button
              variant="secondary"
              onClick={() => navigation("/user/login")}
              style={{
                marginRight: "10px",
                backgroundColor: "rgb(187, 0, 187)",
              }}
            >
              Tamam
            </Button>
            <Button variant="secondary" onClick={toggleSuccessLogin}>
              Kapat
            </Button>
          </HooxModal>

          <Carousel.Caption
            className="text-start"
            style={{
              marginBottom: isMobile ? "0px" : "1%",
              left: isMobile ? "0" : "4%",
              zIndex: "2",
              bottom: isMobile ? "0px" : "1.25rem",
              paddingBottom: isMobile ? "0.25rem" : "1.25rem",
            }}
          >
            <Container>
              <Row className="align-items-center">
                <Col md={{ span: 6 }}>
                  {!isMobile ? (
                    <>
                      <ContentMainTitle isMobile={isMobile}>
                        {content.title}
                      </ContentMainTitle>
                      <Row>
                        <Col className="col-6">
                          <ContentSubData>
                            {content.typeTitle
                              ? `${content.typeTitle} / `
                              : `${""}`}
                            {content.year}
                          </ContentSubData>
                          <Col>
                            {symbols &&
                              symbols.length !== 0 &&
                              symbols.map((item, index) => {
                                return (
                                  <img
                                    src={"/assets/img/rtuk/" + item}
                                    key={index}
                                    style={styles.symbol}
                                  />
                                );
                              })}
                          </Col>
                        </Col>
                        <Col className="col-6  d-flex justify-content-end align-items-center">
                          {content.definitions &&
                            content.definitions.length !== 0 && (
                              <ContentSubDataDefinition>
                                {" "}
                                {content.definitions}{" "}
                              </ContentSubDataDefinition>
                            )}
                        </Col>
                      </Row>
                    </>
                  ) : null}

                  {/* Genre Text */}
                  {!isMobile ? (
                    <div
                      style={{
                        fontFamily: "GilroyMedium",
                        fontSize: "25px",
                        fontWeight: "500",
                        marginTop: "25px",
                      }}
                    >
                      {content.genres
                        ? content.genres.map(function (item, index) {
                            return (
                              <span key={`demo_snap_${index}`}>
                                {(index ? ", " : "") + item}
                              </span>
                            );
                          })
                        : content.genres}
                    </div>
                  ) : null}

                  {/* synopsisLn Text */}
                  {!isMobile ? (
                    <ContentSynopsis>{content.synopsisLn}</ContentSynopsis>
                  ) : null}

                  {/* Actors Text */}
                  {!isMobile ? (
                    <div className="content-detail-desc">
                      <p className="content-detail-actors">
                        {content.actors
                          ? content.actors.map(function (item, index) {
                              return (
                                <span key={`demo_snap_${index}`}>
                                  {(index ? ", " : "") + item}
                                </span>
                              );
                            })
                          : ""}
                      </p>
                    </div>
                  ) : null}

                  {content.isComingSoon == 1 ? (
                    ""
                  ) : (
                    <ContentButtonGroup style={{ marginTop: "1%" }}>
                      {content.type === "MOVIE" ||
                      content.type === "EPISODE" ? (
                        usrRight === USER_RIGHT_BUY_PACKAGE ? (
                          <HooxButton.Secondary
                            variant="primary"
                            className="text-start"
                            style={{
                              borderRadius: "15px",
                              backgroundColor: "white",
                              color: "black",
                              minWidth: "16 0px",
                            }}
                          >
                            <Stack direction="horizontal" gap={2}>
                              <i className="bi bi-play-fill" />
                              <div
                                className="me-auto text-center"
                                style={{ width: "100%" }}
                              >
                                KİRALA İZLE
                              </div>
                            </Stack>
                          </HooxButton.Secondary>
                        ) : (
                          <HooxButton.Secondary
                            variant="outline-secondary"
                            onClick={handleWatch}
                            style={{
                              borderRadius: "15px",
                              backgroundColor: "white",
                              color: "black",
                              width: "160px",
                            }}
                          >
                            <Stack direction="horizontal" gap={2}>
                              <i className="bi bi-play-fill" />
                              <div
                                className="me-auto text-center"
                                style={{ width: "100%" }}
                                id={"watchContent-" + content.id}
                              >
                                İZLE
                              </div>
                            </Stack>
                          </HooxButton.Secondary>
                        )
                      ) : (
                        ""
                      )}
                    </ContentButtonGroup>
                  )}
                </Col>
                <Col md={{ span: 12 }}>
                  <div
                    className="d-flex gap-2 justify-content-md-between justify-content-start align-items-end"
                    style={{
                      width: "100%",
                      marginTop: "10px",
                    }}
                  >
                    <div className="d-flex gap-2">
                      {isLoggedIn && (
                        <HooxButton.Secondary
                          variant="outline-secondary"
                          onClick={handleFavoriteClick}
                          style={{
                            borderColor: "#BB00BB",
                            borderRadius: "15px",
                          }}
                        >
                          {!isFavorited ? (
                            <i className="bi bi-heart"></i>
                          ) : (
                            <i
                              className="bi bi-heart-fill"
                              style={{ color: "#BB00BB" }}
                            ></i>
                          )}
                          {/* {!isFavorited ? `${'LİSTEME EKLE'}` : `${'LİSTEMDEN ÇIKAR'}` } */}
                        </HooxButton.Secondary>
                      )}

                      {isLoggedIn && (
                        <OverlayTrigger
                          trigger="click"
                          placement="top"
                          overlay={sharePopover}
                          rootClose={true}
                        >
                          <HooxButton.Secondary
                            variant="outline-secondary"
                            style={{
                              borderColor: "#BB00BB",
                              borderRadius: "15px",
                            }}
                          >
                            <i className="bi bi-reply-fill icon-flipped"></i>
                          </HooxButton.Secondary>
                        </OverlayTrigger>
                      )}
                    </div>
                    {content.trailerPlayerOKey === null ||
                    content.trailerPlayerPKey === null ? (
                      ""
                    ) : (
                      <div className="d-flex gap-2">
                        <HooxButton.Secondary
                          variant="outline-secondary"
                          style={{
                            borderColor: "#BB00BB",
                            borderRadius: "15px",
                          }}
                          onClick={playPlayer}
                        >
                          <i
                            style={{ color: "white" }}
                            className={
                              isPaused ? "bi bi-play-fill" : "bi bi-pause"
                            }
                          ></i>
                        </HooxButton.Secondary>

                        <HooxButton.Secondary
                          variant="outline-secondary"
                          style={{
                            borderColor: "#BB00BB",
                            borderRadius: "15px",
                          }}
                          onClick={mutePlayer}
                        >
                          <i
                            style={{ color: "white" }}
                            className={
                              isMuted ? "bi bi-volume-mute" : "bi bi-volume-up"
                            }
                          ></i>
                        </HooxButton.Secondary>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      <div style={{ margin: "15px" }}>
        {isMobile ? (
          <>
            <ContentMainTitle isMobile={isMobile}>
              {content.title}
            </ContentMainTitle>
            <div style={{ marginTop: "2%" }}>
              {symbols &&
                symbols.length !== 0 &&
                symbols.map((item, index) => {
                  return (
                    <div style={{ marginTop: "3%", display: "inline" }}>
                      <img
                        src={"/assets/img/rtuk/" + item}
                        key={index}
                        style={styles.symbol}
                      />
                    </div>
                  );
                })}
            </div>

            <ContentSynopsis isMobile={isMobile}>
              {content.synopsisLn}
            </ContentSynopsis>

            <p className="content-detail-actors-mobile">
              {content.actors
                ? content.actors.map(function (item, index) {
                    return (
                      <span key={`demo_snap_${index}`}>
                        {(index ? ", " : "") + item}
                      </span>
                    );
                  })
                : ""}
            </p>
          </>
        ) : null}
      </div>
    </ContentWrapper>
  ) : (
    <Placeholder animation="glow">
      <Placeholder
        xs={12}
        style={{ height: "600px", background: "rgba(255,255,255,.4)" }}
      />
    </Placeholder>
  );
}

const styles = {
  symbol: {
    width: 26,
    marginRight: 8,
  },
};

export default ContentDetailBanner;

import { React, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchContent,
  fetchContentByGuid,
  releaseContent,
} from "./contentSlice";

import { Outlet, useParams, useLocation } from "react-router-dom";
import ContentDetailBanner from "../../components/ContentDetailBanner";

import EpisodeViewer from "../../components/EpisodeViewer";
import { getRelatedContentByGuid } from "../../service/contentService";
import ContentList from "../../components/ContentList";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import MatomoTracking from "../../components/MatomoTracking";
import { useAuth } from "../../components/AuthContext";

function ContentDetail() {
  const { pathname } = useLocation();

  const { contentId } = useParams();

  const { code } = useParams();

  const dispatch = useDispatch();
  const content = useSelector((state) => state.content.detail);
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const [isTrailerPlay, setTrailerPlay] = useState(true);
  const [relatedContents, setRelatedContents] = useState([]);

  const { user } = useAuth();

  const { guid } = user;

  useEffect(() => {
    const isPlay = pathname.slice(-4) === "play";
    if (isPlay && isTrailerPlay) {
      setTrailerPlay(false);
      return;
    }
    if (!isPlay && !isTrailerPlay) {
      setTrailerPlay(true);
      return;
    }
  }, [pathname]);

  function onPlayVideo() {
    setTrailerPlay(false);
  }

  useEffect(() => {
    //console.log("code:", code);
    if (code === "4") {
      //console.log("tyes:", code);
      dispatch(fetchContentByGuid(contentId)); //dispatch(fetchContent(contentId));

      getRelatedContentByGuid(contentId).then((response) => {
        //getRelatedContent(contentId).then((response) => {
        // getRecommended().then((response) => {
        setRelatedContents(response.data);
      });
    } else {
      dispatch(fetchContentByGuid(contentId));

      getRelatedContentByGuid(contentId).then((response) => {
        // getRecommended().then((response) => {
        setRelatedContents(response.data);
      });
    }

    return () => dispatch(releaseContent());
  }, [contentId]);

  //const [fullscreen, setFullscreen] = useState(true);
  //const [show, setShow] = useState(false);

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "view_content",
    content_id: content.guid,
    content_name: content.title,
    user_id: guid,
  });

  var _paq = window._paq = window._paq || [];
  _paq.push(['trackEvent', 'WebContent', content.title, content.guid]);

  return (
    <>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{content.title}</title>
          <meta name="description" content={content.synopsisLn} />
          <meta property="og:title" content={content.title} />
          <meta property="og:description" content={content.synopsisLn} />

          <meta
            property="og:image"
            content="https://imagedelivery.net/qoBmvbtK9_6g_NBy8qZRPw/bc02cbd0-f7d1-4aff-9eb3-1d231552ff00/public"
          />
          <meta
            property="og:url"
            content={"https://www.hoox.tv/" + content.urlName + "/" + contentId}
          />
        </Helmet>
      </div>

      <div style={{ marginTop: isMobile ? "100px" : "" }}>
        <ContentDetailBanner
          content={content}
          trailer={{ isTrailerPlay, onPlayVideo }}
        />
        {content.type === "SERIES" && (
          <EpisodeViewer content={content} onPlayVideo={onPlayVideo} />
        )}
        <ContentList contents={relatedContents} title="Önerilenler" />
        <Outlet />
      </div>
    </>
  );
}

export default ContentDetail;
